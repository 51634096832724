/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import Navbar from "./Navbar";
import { StaticImage } from "gatsby-plugin-image";

const Layout = ({ children }) => {
  return (
    <div
      style={{
        margin: `0 auto`,
        padding: "0 8px",
        maxWidth: 1314,
      }}
    >
      <Navbar />
      <main className="mt-14 lg:mt-0">{children}</main>
      <footer className="flex justify-center align-center pt-3 mb-2 h-8">
        <p className="font-barriecito">Created in calle China, with love &</p>
        <a target="_blank" rel="non" href="https://funksion.site">
          <StaticImage
            height={18}
            src="../images/logoF.png"
            alt="funksion logo"
            className="self-center ml-2"
          />
        </a>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
