import React, { useState } from "react";
import { ArrowIcon, MenuIcon } from "../../atoms/Icons";

import { Link } from "gatsby";
import { LogoNavbar, LogoNavBarMobile } from "../../atoms/Logos";

const Navbar = () => {
  const [display, setDisplay] = useState("hidden lg:flex");
  const [isToggleAbout, setToggleAbout] = useState(false);
  const [isToggleOurArt, setToggleOurArt] = useState(false);
  const [isToggleStore, setToggleStore] = useState(false);

  const handleClick = () => [
    setToggleOurArt(false),
    setToggleAbout(false),
    setToggleStore(false),
    setDisplay("hidden lg:flex"),
  ];

  const handleClickAbout = () =>
    !isToggleAbout ? setToggleAbout(true) : setToggleAbout(false);

  const handleClickOurArt = () =>
    !isToggleOurArt ? setToggleOurArt(true) : setToggleOurArt(false);

  const handleClickStore = () =>
    !isToggleStore ? setToggleStore(true) : setToggleStore(false);

  const handleBlur =
    isToggleAbout || isToggleOurArt || isToggleStore || display === "flex"
      ? "backdrop-blur-sm "
      : "backdrop-blur-none lg:hidden";

  return (
    <>
      <div className="absolute top-2 left-2 z-50 lg:hidden">
        <LogoNavBarMobile />
      </div>
      <div
        className={`${display} absolute h-screen left-0 right-0 z-40 ${handleBlur}`}
        onClick={handleClick}
      />

      <button
        onClick={() =>
          display === "hidden lg:flex"
            ? setDisplay("flex")
            : setDisplay("hidden lg:flex")
        }
      >
        <MenuIcon />
      </button>

      <div className="fixed top-12 right-4 lg:relative lg:top-0 lg:right-0 bg-white z-50 max-w-custom">
        <div
          className={`${display} uppercase lg:normal-case py-2 lg:py-0 flex flex-col lg:flex-row lg:items-center justify-between rounded-md shadow-md lg:shadow-none w-60 lg:w-auto `}
        >
          <div className="pl-4 lg:pl-0 lg:h-auto flex flex-col lg:flex-row lg:items-center lg:w-3/4">
            {/* About */}
            <div className=" py-4 cursor-pointer relative lg:pr-16 lg:p-0 mobile-navbar lg:desktop-navbar ">
              <div className="flex items-center">
                <Link
                  to="/about"
                  className="mobile-navbar lg:desktop-navbar flex"
                >
                  About us
                </Link>
                <div className="" onClick={handleClickAbout}>
                  <ArrowIcon />
                </div>
              </div>

              {isToggleAbout && (
                <div className="transition duration-150 ease-out bg-white uppercase relative lg:absolute flex flex-col justify-evenly h-24  lg:shadow-md lg:rounded-md lg:px-4 lg:mt-2">
                  <Link
                    to="/meet-dana"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Meet Dana
                  </Link>
                  <Link
                    to="/meet-bob"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Meet Bob
                  </Link>
                </div>
              )}
            </div>

            {/* Our Art */}
            <div className="py-4  cursor-pointer relative lg:pr-16 lg:p-0 mobile-navbar lg:desktop-navbar ">
              <div className="flex items-center">
                <Link
                  to="/our-art"
                  className="mobile-navbar lg:desktop-navbar flex"
                >
                  Our Art
                </Link>
                <div className="" onClick={handleClickOurArt}>
                  <ArrowIcon />
                </div>
              </div>
              {isToggleOurArt && (
                <div className="bg-white uppercase relative lg:absolute flex flex-col h-64 justify-evenly lg:shadow-md lg:rounded-md lg:px-4 lg:mt-2">
                  <Link
                    to="/inspiration"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Inspiration
                  </Link>
                  <Link
                    to="/bob-gallery"
                    className="whitespace-nowrap mobile-navbar lg:desktop-navbar "
                  >
                    Bob's Artgallery
                  </Link>
                  <Link
                    to="/dana-gallery"
                    className="whitespace-nowrap  mobile-navbar lg:desktop-navbar "
                  >
                    Dana's Artgallery
                  </Link>
                  <Link
                    to="/photography"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Photography
                  </Link>
                  <Link
                    to="/music"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Music
                  </Link>
                </div>
              )}
            </div>

            {/* Projects0*/}
            <Link
              to="/projects"
              className="py-4 lg:pr-16 mobile-navbar lg:desktop-navbar "
            >
              Projects
            </Link>

            {/* Store */}
            <div className="py-4  cursor-pointer relative lg:pr-16 lg:p-0 mobile-navbar lg:desktop-navbar ">
              <div className="flex items-center">
                <a
                  href="https://barefootartes.myshopify.com/"
                  className="mobile-navbar lg:desktop-navbar flex"
                >
                  Store
                </a>
                <div className="" onClick={handleClickStore}>
                  <ArrowIcon />
                </div>
              </div>
              {isToggleStore && (
                <div className="bg-white uppercase relative lg:absolute flex flex-col h-72 justify-evenly lg:shadow-md lg:rounded-md lg:px-4 lg:mt-2">
                  <a
                    href="https://barefootartes.myshopify.com/collections/bags"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Bags
                  </a>
                  <a
                    href="https://barefootartes.myshopify.com/collections/yoga"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Yoga
                  </a>
                  <a
                    href="https://barefootartes.myshopify.com/collections/beachwear"
                    className="whitespace-nowrap mobile-navbar lg:desktop-navbar "
                  >
                    Beachwear
                  </a>
                  <a
                    href="https://barefootartes.myshopify.com/collections/homewear"
                    className="whitespace-nowrap  mobile-navbar lg:desktop-navbar "
                  >
                    Homewear
                  </a>
                  <a
                    href="https://barefootartes.myshopify.com/collections/accesories"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Accesories
                  </a>
                  <a
                    href="https://barefootartes.myshopify.com/collections/inspiration"
                    className="mobile-navbar lg:desktop-navbar "
                  >
                    Inspiration
                  </a>
                  <a
                    href="https://barefootartes.myshopify.com/collections/tees-hoodies"
                    className="mobile-navbar lg:desktop-navbar whitespace-nowrap "
                  >
                    Tees & Hoodies
                  </a>
                </div>
              )}
            </div>
            <Link
              to="/contact"
              className=" py-4 lg:pr-16 mobile-navbar lg:desktop-navbar "
            >
              Contact Us
            </Link>
          </div>
          <LogoNavbar />
        </div>
      </div>
    </>
  );
};

export default Navbar;
