import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const LogoMobileHome = () => {
  return (
    <div>
      <StaticImage
        width={256}
        height={199}
        src="../images/logo.png"
        alt="logo mobile home"
        placeholder="tracedSVG"
        className="p-8"
      />
    </div>
  );
};
export const LogoDesktopHome = () => {
  return (
    <div>
      <StaticImage
        width={256}
        height={199}
        src="../images/logo.png"
        alt="logo mobile home"
        placeholder="tracedSVG"
      />
    </div>
  );
};
export const LogoNavbar = () => {
  return (
    <Link to="/" className="hidden lg:block">
      <StaticImage
        width={130}
        height={100}
        src="../images/LogoNoBackground.png"
        alt="logo navbar"
        placeholder="tracedSVG"
      />
    </Link>
  );
};

export const LogoNavBarMobile = () => {
  return (
    <Link to="/" className="lg:hidden">
      <StaticImage
        width={70}
        src="../images/LogoNoBackground.png"
        alt="logo navbar"
        placeholder="tracedSVG"
      />
    </Link>
  );
};
