import React from "react"

export const MenuIcon = () => {
  return (
    <div className="lg:hidden fixed top-2 right-2 z-50">
      <div className="p-2" aria-label="hamburguer icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6C22 6.26522 21.8946 6.51957 21.7071 6.70711C21.5196 6.89464 21.2652 7 21 7H3C2.73478 7 2.48043 6.89464 2.29289 6.70711C2.10536 6.51957 2 6.26522 2 6Z"
            fill="black"
          />
          <path
            d="M2 12.032C2 11.7668 2.10536 11.5124 2.29289 11.3249C2.48043 11.1373 2.73478 11.032 3 11.032H21C21.2652 11.032 21.5196 11.1373 21.7071 11.3249C21.8946 11.5124 22 11.7668 22 12.032C22 12.2972 21.8946 12.5516 21.7071 12.7391C21.5196 12.9266 21.2652 13.032 21 13.032H3C2.73478 13.032 2.48043 12.9266 2.29289 12.7391C2.10536 12.5516 2 12.2972 2 12.032Z"
            fill="black"
          />
          <path
            d="M3 17.064C2.73478 17.064 2.48043 17.1694 2.29289 17.3569C2.10536 17.5445 2 17.7988 2 18.064C2 18.3292 2.10536 18.5836 2.29289 18.7711C2.48043 18.9587 2.73478 19.064 3 19.064H21C21.2652 19.064 21.5196 18.9587 21.7071 18.7711C21.8946 18.5836 22 18.3292 22 18.064C22 17.7988 21.8946 17.5445 21.7071 17.3569C21.5196 17.1694 21.2652 17.064 21 17.064H3Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  )
}

export const ArrowIcon = () => {
  return (
    <button className="p-2" aria-label="dropdown arrow icon ">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.84877 10.8372L4.56281 13.4553C4.36116 13.6159 4.03423 13.6159 3.83259 13.4553L0.546629 10.8372C0.344986 10.6766 0.344986 10.4161 0.546629 10.2554C0.748272 10.0948 1.0752 10.0948 1.27684 10.2554L3.68136 12.1712L3.68136 0L4.71404 0L4.71404 12.1712L7.11855 10.2554C7.3202 10.0948 7.64712 10.0948 7.84877 10.2554C8.05041 10.4161 8.05041 10.6766 7.84877 10.8372Z"
          fill="#38B6FF"
        />
      </svg>
    </button>
  )
}

export const ArrowInclined = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="p-1 -ml-2"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8221 4.59686L22.9161 5.39105C23.4128 5.43979 23.8669 5.88059 23.9303 6.37561L24.9644 14.4425C25.0278 14.9375 24.6766 15.2993 24.18 15.2506C23.6833 15.2018 23.2292 14.761 23.1657 14.266L22.409 8.36303L5.99838 25.2682L4.56404 23.8758L20.9747 6.97065L15.0519 6.3895C14.5552 6.34077 14.1011 5.89996 14.0376 5.40494C13.9742 4.90992 14.3254 4.54813 14.8221 4.59686Z"
      fill="#38B6FF"
    />
  </svg>
)

export const TelegramIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mr-2"
  >
    <path
      d="M15.6452 0.645906C15.7144 0.715238 15.7617 0.80338 15.7813 0.899383C15.8009 0.995386 15.7918 1.09502 15.7552 1.18591L9.93619 15.7329C9.88491 15.861 9.79928 15.9725 9.68872 16.0551C9.57817 16.1377 9.44697 16.1881 9.30958 16.2009C9.17218 16.2138 9.03391 16.1884 8.90999 16.1277C8.78606 16.067 8.68129 15.9733 8.60719 15.8569L5.42919 10.8619L0.434191 7.68391C0.317509 7.60989 0.223543 7.50508 0.162661 7.38103C0.10178 7.25699 0.0763493 7.11854 0.0891764 6.98096C0.102004 6.84338 0.15259 6.71201 0.235352 6.60136C0.318115 6.49071 0.429838 6.40508 0.558191 6.35391L15.1052 0.536906C15.1961 0.500318 15.2957 0.491238 15.3917 0.510794C15.4877 0.530351 15.5759 0.577681 15.6452 0.646906V0.645906ZM6.42719 10.5699L9.18819 14.9079L13.9212 3.07591L6.42719 10.5699ZM13.2142 2.36891L1.38219 7.10191L5.72119 9.86191L13.2152 2.36891H13.2142Z"
      fill="black"
    />
  </svg>
)
